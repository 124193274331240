<template>
  <div>
    <v-dialog :fullscreen="fullScreen" v-model="show" v-if="cart">
      <v-card elevation="0">
        <v-toolbar
          dark
          color="primary"
          flat
          style="padding-right: 10px !important"
        >
          <v-toolbar-title class="overline"
            >Venda Nº {{ cart.saleNumber }}</v-toolbar-title
          >
          <v-btn icon @click="print"><v-icon>mdi-printer</v-icon></v-btn>
          <v-btn icon right absolute dark>
            <v-icon
              @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
              >{{ setIconScreen() }}</v-icon
            >
            <v-icon @click="show = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card elevation="0">
          <div id="print">
            <v-card-text v-html="html"></v-card-text>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import moment from "moment";
export default Vue.extend({
  props: ["html", "cart", "visible"],
  data: () => ({
    title: "Detalhe da Venda",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Todos os Pedidos",
            to: "/pedbem/delivered-sales",
            permission: "gerentor.sales.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pedidos em Andamento",
        disabled: true,
        href: "/pedbem/carts",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,
    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Data Fatura",
        align: "start",
        value: "closedDate",
        filtering: true,
      },
      {
        text: "Número",
        align: "start",
        value: "orderNumber",
        filtering: true,
      },
      {
        text: "Status",
        value: "deliveryStatus",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        value: "paymentMethod",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amount",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],

    productHeaders: [
      {
        text: "Descrição",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "QTD",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Estoque",
        align: "start",
        value: "product.stock",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "totalPrice",
        filtering: true,
      },
    ],
    instalmentHeaders: [
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: true,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        align: "start",
        value: "paymentMethod.title",
        filtering: true,
      },
      {
        text: "Valor R$",
        value: "amount",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    print() {
      const prtHtml = document.getElementById("print").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
              <title>Venda ${this.cart.saleNumber}</title>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
