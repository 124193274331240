<template>
  <div>
    <page-header :title="title" :menu="menu" />

    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="950px">
        <v-card elevation="0">
          <v-toolbar>
            <span class="headline" style="font-size: 16px !important"
              >Agenda</span
            >
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    outlined
                    :items="employees"
                    item-value="id"
                    item-text="person.corporateName"
                    label="Profissional"
                    v-model="schedule.professionalId"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    type="date"
                    outlined
                    label="Data Inicial"
                    v-model="schedule.startDate"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    type="date"
                    outlined
                    label="Data Final"
                    v-model="schedule.endDate"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-for="(day, i) in days" :key="i">
                <v-col cols="2" md="3">
                  <v-checkbox
                    v-model="day.checked"
                    :label="day.label"
                    color="indigo"
                    :value="true"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="5" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Inicio"
                    v-model="day.startTime"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Fim"
                    v-model="day.endTime"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Inicio Intervalo"
                    v-model="day.startInterval"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Fim Intervalo"
                    v-model="day.endInterval"
                    required
                  ></v-text-field>
                </v-col>
                <hr class="mt-n4 mb-4" style="width: 100%; opacity: 0.2" />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Fechar
            </v-btn>
            <v-btn
              :loading="loadingAction"
              color="success"
              @click="registerOrUpdate()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <sale-detail
      v-if="saleDetailDialog"
      :cart="sale"
      :visible="saleDetailDialog"
      :html="cartHtml"
      @close="saleDetailDialog = false"
    />
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-between align-center">
            <v-col cols="12" md="4">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" block color="#2ca01c" dark>
                    <v-icon>mdi-plus-thick</v-icon>
                    Ações</v-btn
                  >
                </template>
                <v-list>
                  <v-list-item
                    style="text-transform: none"
                    width="8rem"
                    text
                    @click="(openDialog = true), getEmployees()"
                  >
                    <span>Cadastrar Agenda</span>
                  </v-list-item>
                  <v-list-item
                    style="text-transform: none"
                    width="8rem"
                    text
                    to="/base/sale/create?type=AGENDAMENTO"
                  >
                    <span>Novo Agendamento</span>
                  </v-list-item>
                  <v-list-item
                    width="8rem"
                    text
                    to="/schedules"
                    style="text-transform: none"
                  >
                    <span>Calendário</span>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="schedules"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="item.sale"
              text
              small
              @click="openSaleDetail(item.sale)"
              ><v-icon small class="mr-2">mdi-eye</v-icon></v-btn
            >
            <v-btn text small v-if="!item.saleId" @click="deleteOpen(item.id)">
              <v-icon small class="mr-2">mdi-eraser</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import SaleDetail from "./components/sale-detail.vue";
export default Vue.extend({
  name: "Vendas",
  components: { PageHeader, InternalMenu, SaleDetail },
  data: () => ({
    title: "Listagem Agenda",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Categorias de Produtos",
            to: "/base/categories",
            permission: "gerentor.product.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Clientes",
            to: "/base/customers",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Colaboradores",
            to: "/immobile/employees",
            permission: "genetor.person.employees.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Agenda",
        disabled: true,
        href: "/base/schedules",
      },
    ],
    deleteDialog: false,
    schedules: [],
    employees: [],
    schedule: {
      name: "",
      professionalId: null,
      description: "",
      startDate: null,
      endDate: null,
      days: [],
    },
    openDialog: false,
    items: [],
    instalments: [],
    search: "",
    loading: false,
    saleId: "",
    invoiceModal: false,
    sale: null,
    cartHtml: null,
    payed: {
      accountId: "",
      email: "",
      password: "",
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Profissional",
        align: "start",
        value: "professional.person.corporateName",
        filtering: true,
      },
      {
        text: "Descrição",
        align: "start",
        value: "label",
        filtering: true,
      },
      {
        text: "Reservado para",
        value: "sale.customer.person.corporateName",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],
    saleDetailDialog: false,
    sale: null,
    days: [
      {
        dayOfWeek: 1,
        label: "SEG",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 2,
        label: "TER",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 3,
        label: "QUA",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 4,
        label: "QUI",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 5,
        label: "SEX",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 6,
        label: "SAB",
        startTime: "08:00",
        endTime: "12:00",
        startInterval: "00:00",
        endInterval: "00:00",
        checked: false,
      },
      {
        dayOfWeek: 7,
        label: "DOM",
        startTime: "08:00",
        endTime: "12:00",
        startInterval: "00:00",
        endInterval: "00:00",
        checked: false,
      },
    ],
  }),
  methods: {
    openSaleDetail(sale) {
      this.sale = sale;
      this.getHtml(sale.id);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    openInvoiceModal(item) {
      this.sale = item;
      this.saleId = item.id;
      this.invoiceModal = true;
    },
    closeInvoiceModal() {
      this.payed.email = "";
      this.payed.password = "";
      this.payed.accountId = "";
      this.sale = null;
      this.saleId = null;
      this.invoiceModal = false;
    },

    async getHtml(saleId) {
      await http
        .get(`gerentor/sales/${saleId}/export-html`)
        .then((response) => {
          this.cartHtml = response?.data;
          this.saleDetailDialog = true;
        });
    },

    deleteOpen(itemId) {
      this.saleId = itemId;
      this.deleteDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("schedules").then(
        (data) => {
          this.schedules = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`schedules/${this.saleId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getEmployees() {
      this.loading = true;
      http.get("gerentor/employees").then((data) => {
        this.employees = data.data;
        if (this.employees.length > 0)
          this.professionalId = this.employees[0].id;
        if (this.professionalId) this.getItems();
        this.loading = false;
      });
    },
    registerOrUpdate() {
      const days = this.days.filter((a) => a.checked === true);
      this.schedule.days = days;
      this.loadingAction = true;
      if (this.schedule.id) {
        http.put(`schedules/${this.schedule.id}`, this.schedule).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("schedules", this.schedule).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      (this.schedule = {
        name: "",
        professionalId: null,
        label: "",
      }),
        this.getItems();
    },
    editItem(item) {
      this.schedule = item;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>

